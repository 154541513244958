<template>
  <div>
    <appointment-report-sidebar />
    <appointment-report-details-sidebar />
    <!-- Totalizer -->
    <appointments-totalizer :totalizers="totalizers" />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <!-- Filters -->
      <appointment-list-filters
        ref="filters"
        :search="search"
        :openNewAppointmentSidebar="editAppointment"
      />
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="8">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                class="mr-1"
                variant="outline-primary"
                :disabled="loading"
                @click.prevent="exportList"
              >
                <span class="align-middle">Exportar planilha</span>
              </b-button>
              <b-button
                variant="outline-secondary"
                @click.prevent="clearSearch"
                class="mr-1"
                :disabled="loading"
              >
                Limpar filtros
              </b-button>
              <b-button
                variant="primary"
                @click.prevent="search(1)"
                :disabled="loading"
              >
                <b-spinner v-if="loading" small />
                <feather-icon v-else icon="SearchIcon" class="mr-50" />
                <span>
                  {{ loading ? "Aguarde..." : "Pesquisar" }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        class="position-relative appointment-table"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontrados compromissos para esta busca"
        details-td-class="date-appointment-row"
        :busy="loading"
        :no-local-sorting="true"
        :sort-by="sortField"
        @sort-changed="sort"
      >
        <template
          slot="top-row"
          slot-scope="{ columns }"
          v-if="items.length > 0"
        >
          <td class="date-appointment-row" :colspan="columns">
            {{ items[0].date | localeDate }}
          </td>
        </template>
        <template slot="row-details" slot-scope="{ item }">
          {{ item.nextCategory | localeDate }}
        </template>
        <!-- Column: Nome -->
        <template #cell(name)="data">
          <div class="name-content">
            <b-badge
              variant="lead-name"
              class="name"
              @click="
                goToDetails(data.item.customer_id, data.item.customer_type_id)
              "
            >
              <div
                class="align-text-right text-capitalize text-wrap"
                style="margin-bottom: 4px"
              >
                {{ data.item.name }}
              </div>
            </b-badge>
            <b-badge
              variant="lead-name"
              class="whats"
              v-if="data.item.cell_phone"
              @click="openWhatsapp(data.item.cell_phone)"
            >
              <img src="~@/assets/images/svg/icn_whats.svg" width="15" />
            </b-badge>
          </div>
        </template>
        <!-- Column: Hora -->
        <template #cell(appointment_start)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.appointment_start | onlyTime
            }}</span>
          </div>
        </template>
        <!-- Column: Tipo Evento -->
        <template #cell(desc_event_type)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.desc_event_type
            }}</span>
          </div>
        </template>
        <!-- Column: PN -->
        <template #cell(consultant)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.consultant
            }}</span>
          </div>
        </template>
        <!-- Column: PN especialista -->
        <template #cell(specialist_name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.specialist_name
            }}</span>
          </div>
        </template>
        <!-- Column: Status -->
        <template #cell(status_initials)="data">
          <div class="text-wrap">
            <span
              :id="`appointment-status-${data.item.id}`"
              class="align-text-top text-capitalize"
              >{{ data.item.status_initials }}</span
            >
            <b-tooltip
              :target="`appointment-status-${data.item.id}`"
              :title="data.item.status_description"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: PEF (Pré-entrevista feita) -->
        <template #cell(finished_pre_interview)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.finished_pre_interview | assertion
            }}</span>
            <b-tooltip
              target="finished_pre_interview"
              title="Pré-entrevista feita"
              placement="top"
              boundary="document"
            ></b-tooltip>
          </div>
        </template>
        <!-- Column: Renda -->
        <template #cell(income)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.income
            }}</span>
          </div>
        </template>
        <!-- Column: Profissão -->
        <template #cell(occupation)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.occupation
            }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-wrap text-right">
            <b-button-group>
              <b-button
                :id="`appointment-edit-` + data.item.id"
                v-if="canEdit(data.item)"
                class="btn-icon mr-1"
                variant="flat"
                style="padding: 0"
                @click="editAppointment(data.item.id)"
              >
                <img
                  class="img_action"
                  src="~@/assets/images/svg/icn_edit.svg"
                />
              </b-button>
              <b-tooltip
                :target="`appointment-edit-` + data.item.id"
                v-if="canEdit(data.item)"
                title="Editar Compromisso"
                placement="top"
                boundary="document"
              ></b-tooltip>

              <b-button
                :id="`appointment-report-` + data.item.id"
                v-if="canReport(data.item)"
                class="btn-icon mr-1"
                variant="flat"
                style="padding: 0"
                @click="reportAppointment(data.item.id)"
              >
                <img
                  class="img_action"
                  src="~@/assets/images/svg/icn_report_appointment.svg"
                />
              </b-button>
              <b-tooltip
                :target="`appointment-report-` + data.item.id"
                v-if="canReport(data.item)"
                title="Reportar Compromisso"
                placement="top"
                boundary="document"
              ></b-tooltip>

              <b-dropdown
                variant="link"
                no-caret
                right
                toggle-class="pl-0"
                v-if="data.item.is_owner || data.item.has_report"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  v-if="data.item.is_owner"
                  @click="deleteRow(data.item)"
                >
                  <feather-icon class="text-body" icon="TrashIcon" />
                  <span class="align-middle ml-50">Remover compromisso</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.has_report"
                  @click="openReportDetails(data.item.id)"
                >
                  <feather-icon class="text-body" icon="FileTextIcon" />
                  <span class="align-middle ml-50">Detalhes do report</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!data.item.is_interview"
                  @click="resendEmailInterview(data.item)"
                >
                  <feather-icon class="text-body" icon="RepeatIcon" />
                  <span class="align-middle ml-50"
                    >Reenviar pré entrevista por email</span
                  >
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!data.item.is_interview"
                  @click="resendWhatsInterview(data.item)"
                >
                  <img src="~@/assets/images/svg/icn_whats.svg" width="15" />
                  <span class="align-middle ml-50"
                    >Reenviar pré entrevista por whatsapp</span
                  >
                </b-dropdown-item>
              </b-dropdown>
            </b-button-group>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BButtonGroup,
  BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { avatarText } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import AppointmentListFilters from "../components/AppointmentListFilters";
import AppointmentsTotalizer from "../components/AppointmentsTotalizer";
import AppointmentReportSidebar from "../components/AppointmentReportSidebar";
import AppointmentReportDetailsSidebar from "../components/AppointmentReportDetailsSidebar";
import { stringToDateTime } from "@/helpers/converters";
import * as accountTypes from "@/modules/account/store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import { OWN } from "@/constants/structure_options";
import { EA, MXA, PA, NA, EA_PN } from "@/constants/appointment_status";
import { createWppAppointmentMessage } from "@/helpers/whatsapp_messages";
import * as customerTypes from "@/constants/customers_types";
import { getRangeDates } from "@/helpers/date_picker";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BButtonGroup,
    BTooltip,
    vSelect,
    AppointmentListFilters,
    AppointmentsTotalizer,
    AppointmentReportSidebar,
    AppointmentReportDetailsSidebar,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        { key: "name", label: "Nome", stickyColumn: true },
        { key: "appointment_start", label: "Hora" },
        { key: "desc_event_type", label: "Tipo Evento", sortable: true },
        { key: "consultant", label: "PN", sortable: true },
        { key: "specialist_name", label: "PN especialista", sortable: true },
        { key: "status_initials", label: "Status", sortable: true },
        {
          key: "finished_pre_interview",
          label: "PEF",
          sortable: true,
          thAttr: { id: "finished_pre_interview" },
        },
        { key: "customer_type", label: "Tipo", sortable: true },
        { key: "income", label: "Renda", sortable: true },
        { key: "occupation", label: "Profissão", sortable: true },
        { key: "actions", label: "Ações" },
      ],
      currentPage: 1,
      itemsPerPage: 1,
      order: undefined,
      sortField: null,
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters({
      appointmentsSearchResult: types.APPOINTMENTS_SEARCH_RESULT,
      appointmentsSearchTotalizers: types.APPOINTMENTS_SEARCH_TOTALIZERS,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    items: function () {
      var appointments = this.appointmentsSearchResult?.data || [];
      appointments.forEach((item, index) => {
        if (
          appointments[index + 1] !== undefined &&
          item.date !== appointments[index + 1].date
        ) {
          item._showDetails = true;
          item.nextCategory = appointments[index + 1].date;
        }
      });
      return appointments;
    },
    totalItems: function () {
      return this.appointmentsSearchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.appointmentsSearchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.appointmentsSearchResult?.to || 0;
    },
    totalizers: function () {
      return this.appointmentsSearchTotalizers || {};
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search();
    this.verifyPendencies();
  },
  methods: {
    ...mapActions({
      searchAppointments: types.SEARCH_APPOINTMENTS,
      openNewAppointmentSidebar: types.OPEN_NEW_APPOINTMENT_SIDEBAR,
      openAppointmentReportSidebar: types.OPEN_APPOINTMENT_REPORT_SIDEBAR,
      openAppointmentReportDetailsSidebar:
        types.OPEN_APPOINTMENT_REPORT_DETAILS_SIDEBAR,
      getAppointmentReportPendencies: types.GET_APPOINTMENT_REPORT_PENDENCIES,
      exportAppointments: types.EXPORT_APPOINTMENTS,
      sendWhatsappInterview: sharedTypes.SEND_INTERVIEW_WHATSAPP,
      sendInterviewEmail: sharedTypes.SEND_INTERVIEW_EMAIL,
      deleteAppointment: types.DELETE_APPOINTMENT,
    }),
    search(currentPage) {
      this.loading = true;
      const {
        customer,
        rangeDate,
        recommendedBy,
        eventType,
        consultant,
        franchise,
        structureOption,
        incomeBracket,
      } = this.$refs.filters;
      const range_date = getRangeDates(rangeDate);
      this.searchAppointments({
        customer_id: customer,
        appointment_start: range_date.start,
        appointment_end: range_date.end,
        customer_reference: recommendedBy,
        event_type: eventType,
        consultant_id: consultant,
        franchise,
        structure_option: structureOption,
        income_bracket: incomeBracket,
        limit: this.itemsPerPage,
        order: this.order,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os compromissos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editAppointment(id) {
      this.openNewAppointmentSidebar({
        id,
        saveAction: () => {
          this.search(this.currentPage);
        },
      });
    },
    canEdit(appointment) {
      const dateStart = new Date(
        stringToDateTime(appointment.appointment_start)
      );
      const now = new Date();
      if (dateStart > now) {
        if (
          !appointment.has_report &&
          appointment.is_owner &&
          (appointment.status_id == EA ||
            appointment.status_id == MXA ||
            appointment.status_id == PA ||
            appointment.status_id == NA)
        ) {
          return true;
        }
      }
      return false;
    },
    reportAppointment(id, blocked = false) {
      this.openAppointmentReportSidebar({
        appointment_id: id,
        blocked,
        saveAction: () => {
          this.search(this.currentPage);
          this.verifyPendencies();
        },
      });
    },
    canReport(appointment) {
      if (
        !appointment.has_report &&
        appointment.is_owner &&
        (appointment.status_id == EA ||
          appointment.status_id == MXA ||
          appointment.status_id == PA ||
          appointment.status_id == NA ||
          appointment.status_id == EA_PN)
      ) {
        return true;
      }
      return false;
    },
    openReportDetails(id) {
      this.openAppointmentReportDetailsSidebar({ id });
    },
    deleteRow(appointment) {
      const dateStart = new Date(
        stringToDateTime(appointment.appointment_start)
      );
      const now = new Date();
      const appointmentCreationDate = new Date(appointment.created_at);
      const aDayAfterAppointmentCreationDate = new Date(
        new Date(appointmentCreationDate).getTime() + 60 * 60 * 24 * 1000
      );

      if (now >= aDayAfterAppointmentCreationDate) {
        this.$swal({
          title: "Não é possível remover!",
          text: `Não é possível remover o compromisso depois de 24h após sua criação. Por favor faça o report do compromisso.`,
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Continuar",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        this.reportAppointment(appointment.id);
      } else if (dateStart > now) {
        this.$swal({
          title: "Confirmação",
          text: `Deseja realmente remover o compromisso?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
          background: `${this.skin === "dark" ? "#283046" : ""}`,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-secondary ml-1",
            htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
          },
        }).then((result) => {
          if (result.value) {
            this.deleteAppointment(appointment.id)
              .then((response) => {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Sucesso",
                    text: "Removido com sucesso!",
                    icon: "CoffeeIcon",
                    variant: "success",
                  },
                });
                this.search(this.currentPage);
              })
              .catch((err) => {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Oops!",
                    text: "Não foi possível remover o compromisso. Entre em contato com o setor de TI.",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              });
          }
        });
      } else {
        this.$swal({
          title: "Não é possível remover!",
          text: `Não é possível remover o compromisso após o horário de início.`,
          icon: "error",
          background: `${this.skin === "dark" ? "#283046" : ""}`,
          showCancelButton: false,
          confirmButtonText: "Continuar",
          customClass: {
            confirmButton: "btn btn-primary",
            htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
          },
        });
      }
    },
    exportList() {
      this.loading = true;
      const {
        customer,
        rangeDate,
        recommendedBy,
        eventType,
        consultant,
        franchise,
        structureOption,
        incomeBracket,
      } = this.$refs.filters;
      var appointmentStart = undefined;
      var appointmentEnd = undefined;
      if (rangeDate) {
        appointmentStart = rangeDate.split(" até ")[0];
        appointmentEnd = rangeDate.split(" até ")[1];
      }
      this.exportAppointments({
        customer_id: customer,
        appointment_start: appointmentStart,
        appointment_end: appointmentEnd,
        customer_reference: recommendedBy,
        event_type: eventType,
        consultant_id: consultant,
        franchise,
        structure_option: structureOption,
        income_bracket: incomeBracket,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao exportar os compromissos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    verifyPendencies() {
      this.getAppointmentReportPendencies().then((response) => {
        if (response.data.id) {
          if (!this.$swal.isVisible()) {
            this.showBlockMessage();
          } else {
            this.$swal.update({
              didClose: () => this.showBlockMessage(),
            });
          }
          this.reportAppointment(response.data.id, true);
        }
      });
    },
    showBlockMessage() {
      this.$swal({
        title: "Acesso bloqueado",
        text: `Seu acesso ficará bloqueado enquanto não finalizar o report dos compromissos. Para reabilitar o sistema, finalize o report.`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Continuar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      });
    },
    sort(event) {
      if (event.sortBy && event.sortBy !== "") {
        //clear in third click
        if (!event.sortDesc && this.sortField == event.sortBy) {
          this.order = undefined;
          this.sortField = null;
        } else {
          var direction = event.sortDesc ? " DESC" : " ASC";
          this.order = event.sortBy + direction;
          this.sortField = event.sortBy;
        }
        this.search();
      }
    },
    clearSearch() {
      this.$refs.filters.customer = undefined;
      this.$refs.filters.rangeDate = undefined;
      this.$refs.filters.eventType = undefined;
      this.$refs.filters.consultant = undefined;
      this.$refs.filters.franchise = undefined;
      this.$refs.filters.recommendedBy = undefined;
      this.$refs.filters.structureOption = OWN;
      this.$refs.filters.incomeBracket = [0, 20000];
      this.search(1);
    },
    openWhatsapp(cell_phone) {
      setTimeout(() => {
        window.open("https://wa.me/" + cell_phone, "_blank").focus();
      });
    },
    resendEmailInterview(appointment) {
      this.sendInterviewEmail(appointment.token)
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso!",
              text: "E-mail enviado com sucesso.",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao enviar o email. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    goToDetails(customerId, customerType) {
      if (customerType === customerTypes.CUSTOMER) {
        var routeData = this.$router.resolve({
          name: "customer-profile",
          params: { id: customerId },
        });
      } else if (
        customerType === customerTypes.LEAD_PN ||
        customerType === customerTypes.PN
      ) {
        var routeData = this.$router.resolve({
          name: "lead-pn-profile",
          params: { id: customerId },
        });
      } else {
        var routeData = this.$router.resolve({
          name: "lead-profile",
          params: { id: customerId },
        });
      }
      setTimeout(() => {
        window.open(routeData.href, "_blank");
      });
    },
    resendWhatsInterview(appointment) {
      this.sendWhatsappInterview(appointment.token)
        .then((response) => {
          var message = createWppAppointmentMessage(
            appointment.name,
            appointment.token,
            response.data.token_public
          );
          setTimeout(() => {
            window.open(
              "https://api.whatsapp.com/send?phone=" +
                appointment.cell_phone +
                "&text=" +
                message,
              "_blank"
            );
          });
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao enviar a mensagem. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.img_action {
  width: 16px;
  height: 16px;
}

.dropdown-toggle-no-caret {
  padding-left: 0 !important;
}

.appointment-table .name-content .name:hover {
  background-color: #f26237;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.table.b-table > tbody > tr.b-table-details > td.date-appointment-row {
  border-top: 1px solid #ebe9f1 !important;
}

.dark-layout
  .table.b-table
  > tbody
  > tr.b-table-details
  > td.date-appointment-row {
  border-top: 1px solid #3b4253 !important;
}

.date-appointment-row {
  background-color: #f39376 !important;
}

.dark-layout .date-appointment-row {
  background-color: #824442 !important;
}
</style>

<template>
  <div>
    <b-sidebar
      id="sidebar-report-appointment"
      :visible="appointmentReportSidebar.visible"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      :no-close-on-esc="isBlocked"
      :no-close-on-backdrop="isBlocked"
      right
      @change="(val) => mutateAppointmentReportSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4>Report de compromisso</h4>
          <div>
            <feather-icon
              v-if="!isBlocked"
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <div
          v-if="loading.appointment"
          class="d-flex justify-content-center m-2"
        >
          <b-spinner variant="primary" />
        </div>
        <b-form
          class="p-2"
          v-if="appointment && !contractReportOption"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="6" class="mb-1"> Nome do cliente: </b-col>
            <b-col cols="6" class="mb-1">
              {{ appointment.customer_name }}
            </b-col>
            <b-col cols="6" class="mb-1"> Data do agendamento: </b-col>
            <b-col cols="6" class="mb-1">
              {{ appointment.appointment_start | dateTime }}
            </b-col>
            <b-col cols="6" class="mb-1"> Tipo de compromisso: </b-col>
            <b-col cols="6" class="mb-1">
              {{ appointment.appointment_envent_type.description }}
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" class="mb-1">
              <b-form-group
                :label="isPn ? 'Houve reunião com o PN?' : 'Houve reunião?'"
                label-for="appointment-report-had-meeting"
              >
                <v-select
                  id="appointment-report-had-meeting"
                  v-model="hadMeeting"
                  :options="assertOptions"
                  :reduce="(options) => options.value"
                  label="name"
                  :class="getSelectErrorClass(v$.hadMeeting.$error)"
                  @input="closedDeal = why = rescheduleMeeting = null"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.hadMeeting.required.$invalid">
                    Você deve informar se houve reunião
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="hadMeeting === 'true'">
            <b-col cols="12" class="mb-1" v-if="!isPn">
              <b-form-group
                label="Fechou negócio?"
                label-for="appointment-report-closed-deal"
              >
                <v-select
                  id="appointment-report-closed-deal"
                  v-model="closedDeal"
                  :options="assertOptions"
                  :reduce="(options) => options.value"
                  label="name"
                  :class="getSelectErrorClass(v$.closedDeal.$error)"
                  @input="why = null"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.closedDeal.required.$invalid">
                    Você deve informar se o negócio foi fechado
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mb-1" v-else>
              <b-form-group
                label="Foi aprovado?"
                label-for="appointment-has-approval"
              >
                <v-select
                  id="appointment-has-approval"
                  v-model="hasApproval"
                  :options="assertOptions"
                  :reduce="(options) => options.value"
                  label="name"
                  :class="getSelectErrorClass(v$.hasApproval.$error)"
                  @input="why = null"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.hasApproval.required.$invalid">
                    Você deve informar se o PN foi aprovado
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-if="
              hadMeeting === 'false' ||
              closedDeal === 'false' ||
              hasApproval === 'false'
            "
          >
            <b-col cols="12" class="mb-1">
              <b-form-group
                label="Qual o motivo?"
                label-for="appointment-report-why"
              >
                <v-select
                  id="appointment-report-why"
                  v-model="why"
                  :options="whyOptions"
                  :reduce="(options) => options.name"
                  label="name"
                  :class="getSelectErrorClass(v$.why.$error)"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.why.required.$invalid">
                    Você deve informar um motivo
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="hadMeeting === 'true' && appointment.event_type == appointmentTypes.X_MOMENT">
            <b-col cols="12" class="mb-1">
              <b-form-group label="Arquivo do plano financeiro gerado a partir da planilha de MX" label-for="mx-file">
                <div
                  class="custom-file b-form-file"
                  @click="$refs.file.click()"
                >
                  <input
                    id="mx-file"
                    ref="file"
                    custom-file-label="Buscar"
                    type="file"
                    class="custom-file-input"
                    accept="application/pdf"
                    style="z-index: -5"
                    required
                    @input="selectedMxFile"
                  />
                  <label data-browse="Browse" class="custom-file-label">
                    <span v-if="!mxFile">Anexar plano financeiro...</span>
                    <span v-else>{{ mxFile.name }}</span>
                  </label>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="hadMeeting === 'false'">
            <b-col cols="12" class="mb-1">
              <b-form-group
                label="Deseja remarcar a reunião?"
                label-for="appointment-report-reschedule-meeting"
              >
                <v-select
                  id="appointment-report-reschedule-meeting"
                  v-model="rescheduleMeeting"
                  :options="assertOptions"
                  :reduce="(options) => options.value"
                  label="name"
                  :class="getSelectErrorClass(v$.rescheduleMeeting.$error)"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.rescheduleMeeting.required.$invalid">
                    Você deve escolher uma opção
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="rescheduleMeeting === 'true' && hadMeeting === 'false'">
            <b-col cols="12" md="6" class="mb-1">
              <b-form-group
                label="Data de início"
                label-for="meeting-start-date"
              >
                <flat-pickr
                  id="meeting-start-date"
                  v-model="meetingStart"
                  class="form-control"
                  :config="flatPickrConfig"
                  :class="{
                    'is-invalid':
                      v$.meetingStart.$error || overlappingAppointments,
                  }"
                  @input="setEndDate"
                  @on-change="checkOverlapping"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.meetingStart.required.$invalid">
                    Você deve informar uma data de início
                  </span>

                  <span v-if="overlappingAppointments">
                    Horário já reservado para outro compromisso
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mb-1">
              <b-form-group
                label="Data de término"
                label-for="meeting-end-date"
              >
                <flat-pickr
                  id="meeting-end-date"
                  v-model="meetingEnd"
                  class="form-control"
                  :config="flatPickrConfig"
                  :class="{
                    'is-invalid':
                      v$.meetingEnd.$error || overlappingAppointments,
                  }"
                  @on-change="checkOverlapping"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.meetingEnd.required.$invalid">
                    Você deve informar uma data de término
                  </span>
                  <span v-else-if="v$.meetingEnd.valid.$invalid">
                    Você deve informar uma data superior a de início
                  </span>
                  <span v-if="overlappingAppointments">
                    Horário já reservado para outro compromisso
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mb-1">
              <b-form-group
                label="Endereço presencial / link online"
                label-for="appointment-room-link"
              >
                <b-form-input
                  id="appointment-room-link"
                  v-model="roomLink"
                  :class="{
                    'is-invalid': v$.roomLink.$error,
                  }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.roomLink.required.$invalid">
                    Você deve informar um endereço ou link para reunião
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" class="mb-2">
              <b-form-group
                label="Comentários do report"
                label-for="appointment-report-feedback"
              >
                <b-form-textarea
                  id="appointment-report-feedback"
                  v-model="feedback"
                  :class="{ 'is-invalid': v$.feedback.$error }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <template
            v-if="
              !isPn && (!isInterview || appointment.consultant_specialist_id)
            "
          >
            <b-row>
              <b-col cols="12">
                <h5>Avaliação do PN suporte</h5>
              </b-col>
            </b-row>
            <b-row v-if="!isInterview">
              <b-col cols="12" class="mb-1">
                <b-form-group
                  label="Houve um especialista suporte lhe auxiliando?"
                  label-for="appointment-report-had-support"
                >
                  <v-select
                    id="appointment-report-had-support"
                    v-model="hadSupport"
                    :options="assertOptions"
                    :reduce="(options) => options.value"
                    label="name"
                    :class="getSelectErrorClass(v$.hadMeeting.$error)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <template v-if="isInterview || hadSupport === 'true'">
              <b-row>
                <b-col cols="12" class="mb-1">
                  <b-form-group
                    label="Especialista suporte"
                    label-for="appointment-report-specialist"
                  >
                    <v-select
                      id="appointment-report-specialist"
                      v-model="specialistId"
                      :options="consultantsSpecialist"
                      :reduce="
                        (consultant_specialist) => consultant_specialist.id
                      "
                      label="name"
                      :class="getSelectErrorClass(v$.specialistId.$error)"
                    />
                    <div class="invalid-feedback">
                      <span v-if="v$.specialistId.required.$invalid">
                        Você deve informar o especialista suporte
                      </span>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="mb-1">
                  <b-form-group label="Dê uma nota de 1 a 5">
                    <v-select
                      id="appointment-report-specialist"
                      v-model="specialistScore"
                      :options="scores"
                      :reduce="(options) => options.value"
                      label="value"
                      :class="getSelectErrorClass(v$.specialistScore.$error)"
                    />
                    <div class="invalid-feedback">
                      <span v-if="v$.specialistScore.required.$invalid">
                        Você deve informar uma nota
                      </span>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="mb-1">
                  <b-form-group
                    label="Comentários da avaliação"
                    label-for="appointment-report-specialist-feedback"
                  >
                    <b-form-textarea
                      id="appointment-report-specialist-feedback"
                      v-model="specialistFeedback"
                      :class="{ 'is-invalid': v$.specialistFeedback.$error }"
                    />
                    <div class="invalid-feedback">
                      <span v-if="v$.specialistFeedback.required.$invalid">
                        Você deve informar um comentário da avaliação
                      </span>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
          </template>

          <!-- Form Actions -->
          <div class="d-flex justify-content-between">
            <b-button
              :disabled="saving || overlappingAppointments"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="lead-buttons"
            >
              <b-spinner v-if="saving" small />
              {{ saving ? "Salvando..." : "Salvar" }}
            </b-button>
          </div>
        </b-form>
        <div class="p-2 mt-1" v-if="contractReportOption">
          <h5>Deseja realizar o report dos contratos agora ou reportar depois?</h5>
          <div class="d-flex justify-content-between mt-3">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="onContractReport"
            >
              Reportar agora
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="dismiss"
            >
              Reportar depois
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormTextarea,
  BFormInput,
  BCol,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import vSelect from "vue-select";
import VueSlider from "vue-slider-component";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { getVueSelectErrorClass } from "@/helpers/validators";
import * as appointmentTypes from "@/constants/appointment_types";
import * as types from "../store/types";
import { assertOptions } from "@/constants/assertions";
import {
  notHadWhyOptions,
  notClosedWhyOptionsForCustomer,
  notClosedWhyOptionsForPN,
  scores,
} from "@/constants/appointment_report_options";
import { EA_PN } from "@/constants/appointment_status";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { stringToDateTime, dateTimeToString } from "@/helpers/converters";
import moment from "moment";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BCol,
    BRow,
    BSpinner,
    vSelect,
    VueSlider,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      appointmentTypes,
      loading: {
        appointment: false,
        consultantSpecialists: false,
      },
      assertOptions,
      scores,
      appointment: null,
      hadMeeting: null,
      closedDeal: null,
      why: null,
      feedback: null,
      hadSupport: "false",
      specialistId: null,
      specialistFeedback: null,
      specialistScore: null,
      hasApproval: null,
      saving: false,
      rescheduleMeeting: null,
      meetingStart: null,
      meetingEnd: null,
      flatPickrConfig: {
        dateFormat: "d/m/Y H:i",
        enableTime: true,
        locale: Portuguese,
        minDate: "today",
      },
      overlappingAppointments: false,
      roomLink: null,
      mxFile: null,
      contractReportOption: false
    };
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations() {
    return {
      hadMeeting: { required },
      rescheduleMeeting: {
        required: requiredIf((value) => {
          if (this.hadMeeting === "false") {
            return true;
          }
          return false;
        }),
      },
      meetingStart: {
        required: requiredIf((value) => {
          if (this.rescheduleMeeting === "true") {
            return true;
          }
          return false;
        }),
      },
      meetingEnd: {
        required: requiredIf((value) => {
          if (this.rescheduleMeeting === "true") {
            return true;
          }
          return false;
        }),
        valid(date) {
          if (this.meetingStart) {
            const start = moment(this.meetingStart, "DD/MM/YYYY hh:mm");
            const end = moment(date, "DD/MM/YYYY hh:mm");
            return end > start;
          }
          return true;
        },
      },
      closedDeal: {
        required: requiredIf((value, vm) => {
          if (this.hadMeeting === "true" && !vm.isPn) {
            return true;
          }
          return false;
        }),
      },
      hasApproval: {
        required: requiredIf((value, vm) => {
          if (this.hadMeeting === "true" && vm.isPn) {
            return true;
          }
          return false;
        }),
      },
      why: {
        required: requiredIf((value) => {
          if (
            this.hadMeeting === "false" ||
            this.closedDeal === "false" ||
            this.hasApproval === "false"
          ) {
            return true;
          }
          return false;
        }),
      },
      feedback: {},
      hadSupport: { required },
      specialistId: {
        required: requiredIf((value) => {
          if (this.hadSupport === "true") {
            return true;
          }
          return false;
        }),
      },
      specialistScore: {
        required: requiredIf((value) => {
          if (this.hadSupport === "true") {
            return true;
          }
          return false;
        }),
      },
      specialistFeedback: {
        required: requiredIf((value) => {
          if (
            this.hadSupport === "true" &&
            (!this.specialistScore || this.specialistScore < 4)
          ) {
            return true;
          }
          return false;
        }),
      },
      roomLink: {
        required: requiredIf((value) => {
          if (this.rescheduleMeeting === "true") {
            return true;
          }
          return false;
        }),
      },
      mxFile: {
        required: requiredIf((value) => {
          if (this.hadMeeting === 'true' 
            && this.appointment.event_type == appointmentTypes.X_MOMENT
          ) {
            return true;
          }
          return false;
        }),
      },
    };
  },
  watch: {
    appointmentReportSidebar() {
      if (this.appointmentReportSidebar.visible) {
        this.onShow();
      }
    },
  },
  computed: {
    ...mapGetters({
      appointmentReportSidebar: types.APPOINTMENT_REPORT_SIDEBAR,
      consultantsSpecialist: types.CONSULTANTS_SPECIALIST,
    }),
    whyOptions: function () {
      if (
        this.hadMeeting === "true" &&
        (this.closedDeal === "false" || this.hasApproval === "false")
      ) {
        if (this.isPn) {
          return notClosedWhyOptionsForPN;
        } else {
          return notClosedWhyOptionsForCustomer;
        }
      }
      return notHadWhyOptions;
    },
    isBlocked: function () {
      if(this.appointmentReportSidebar?.blocked || this.contractReportOption){
        return true
      }
      return false
    },
    isInterview: function () {
      return (
        this.appointment?.appointment_envent_type.id ===
        appointmentTypes.INTERVIEW
      );
    },
    isPn: function () {
      return this.appointment?.status_id == EA_PN;
    },
  },
  methods: {
    ...mapMutations({
      mutateAppointmentReportSidebar: types.MUTATE_APPOINTMENT_REPORT_SIDEBAR,
    }),
    ...mapActions({
      getAppointment: types.GET_APPOINTMENT,
      storeReport: types.STORE_REPORT,
      getConsultantsSpecialist: types.GET_CONSULTANTS_SPECIALIST,
      storeAppointment: types.STORE_APPOINTMENT,
      checkForOverlappingAppointments: types.CHECK_FOR_OVERLAPPING_APPOINTMENTS,
    }),
    onShow() {
      this.loading.consultantSpecialists = true;
      this.getConsultantsSpecialist(
        this.appointmentReportSidebar?.appointment_id
      )
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os consultores especialistas para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.consultantSpecialists = false;
        });

      this.loading.appointment = true;
      this.getAppointment(this.appointmentReportSidebar?.appointment_id)
        .then((response) => {
          this.appointment = response.data;
          if (this.appointment?.appointment_report) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "O compromisso já foi reportado anteriormente!",
                icon: "AlertTriangleIcon",
                variant: "warning",
              },
            });
            this.mutateAppointmentReportSidebar({ visible: false });
            this.clear();
            return;
          }
          if (this.appointment?.consultant_specialist) {
            this.specialistId = this.appointment?.consultant_specialist_id;
          }
        })
        .catch((err) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar o compromisso para report. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.appointment = false;
        });
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;
      const {
        hadMeeting,
        closedDeal,
        hasApproval,
        why,
        feedback,
        specialistId,
        specialistFeedback,
        specialistScore,
        mxFile
      } = this;
      const payload = {
        appointment_id: this.appointmentReportSidebar?.appointment_id,
        had_meeting: hadMeeting,
        closed_deal: closedDeal,
        has_approval: hasApproval,
        why,
        feedback,
        consultant_specialist_id: specialistId,
        consultant_specialist_feedback: specialistFeedback,
        consultant_specialist_score: specialistScore,
        mx_file: mxFile
      }
      const request = new FormData();
      for (let [key, value] of Object.entries(payload)) {
        if (value && value !== undefined && value !== "" && value !== "null") {
          request.append(key, value);
        }
      }
      this.storeReport(request)
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "Report salvo com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          if(closedDeal === "true" && 
            (this.appointment.event_type == appointmentTypes.X_MOMENT ||
            this.appointment.event_type == appointmentTypes.PARTNERSHIP)
          ){
            this.contractReportOption = true;
          }else{
            this.dismiss()
          }
          
        })
        .catch((err) => {
          if (err.response.status == 405) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Não é possivel Salvar. " + err.response.data,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao salvar o report. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .finally(() => {
          this.saving = false;
        });

      if (this.rescheduleMeeting === "true") {
        this.storeAppointment({
          appointment_start: stringToDateTime(this.meetingStart),
          appointment_end: stringToDateTime(this.meetingEnd),
          consultant_id: this.appointment.consultant_id,
          customer_id: this.appointment.customer_id,
          event_type: this.appointment.event_type,
          meeting_room_link: this.roomLink,
        })
          .then((response) => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Sucesso",
                text: "Compromisso criado com sucesso!",
                icon: "CoffeeIcon",
                variant: "success",
              },
            });
          })
          .catch((err) => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao criar o compromisso. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.saving = false;
          });
      }
    },
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
    setEndDate() {
      if (this.meetingStart) {
        var time = moment(this.meetingStart, "DD/MM/YYYY hh:mm").add(2, "h");
        this.meetingEnd = time.toDate();
      }
    },
    checkOverlapping() {
      setTimeout(() => {
        this.checkForOverlappingAppointments({
          appointment_start: stringToDateTime(this.meetingStart),
          appointment_end: stringToDateTime(this.meetingEnd),
          consultant_id: this.appointment?.consultant_id,
        })
          .then((response) => {
            this.overlappingAppointments = false;
          })
          .catch((err) => {
            this.overlappingAppointments = true;
          });
      }, 200);
    },
    selectedMxFile(event) {
      if (event.target.files.length > 0) {
        this.mxFile = event.target.files[0];
      }
    },
    clear() {
      this.appointment = null;
      this.hadMeeting = null;
      this.closedDeal = null;
      this.hasApproval = null;
      this.why = null;
      this.feedback = null;
      this.hadSupport = "false";
      this.specialistId = null;
      this.specialistFeedback = null;
      this.specialistScore = null;
      this.rescheduleMeeting = null;
      this.meetingStart = null;
      this.meetingEnd = null;
      this.roomLink = null;
      this.mxFile = null;
      this.contractReportOption = false;
      this.v$.$reset();
    },
    onContractReport() {
      this.mutateAppointmentReportSidebar({ visible: false });
      this.clear();
      this.$router.push({ 
        name: 'contract-save', 
        params: { 
          'blocked': false, 
          'appointment': this.appointmentReportSidebar.appointment_id
        } 
      })
    },
    dismiss() {
      this.mutateAppointmentReportSidebar({ visible: false });
      this.clear();
      this.appointmentReportSidebar.saveAction();
    }
  },
};
</script>

<style lang="scss">
.sidebar-xg {
  width: 36rem;
}

.lead-buttons {
  width: 10rem;
}
</style>

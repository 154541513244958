<template>
  <b-row class="match-height">
    <b-col md="4">
      <b-card
        class="earnings-card"
      >
        <b-row>
          <b-col class="mb-2 mb-md-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <div class="circle appointment">
                  <img class="img_icon" src="~@/assets/images/svg/icn_all_leads.svg" />
                </div>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalizer.scheduled_interviews }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Entrevistas agendadas
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col md="4">
      <b-card
        class="earnings-card"
      >
        <b-row>
          <b-col md="2" sm="6" class="mb-2 mb-md-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <div class="circle appointment">
                  <img class="img_icon" src="~@/assets/images/svg/icn_all_leads.svg" />
                </div>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalizer.scheduled_x_moment }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  MXA
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col md="4">
      <b-card
        class="earnings-card"
      >
        <b-row>
          <b-col md="2" sm="6" class="mb-2 mb-md-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <div class="circle appointment">
                  <img class="img_icon" src="~@/assets/images/svg/icn_all_leads.svg" />
                </div>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalizer.scheduled_partnership }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  PA
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BPopover, BButton
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    VueApexCharts,
    BPopover,
    BButton
  },
  props: {
    totalizers: {
      type: Object,
      default: {
        scheduled_interviews: 0,
        scheduled_partnership: 0,
        scheduled_x_moment: 0
      },
    },
  },
  computed: {
    totalizer: function(){
      return this.totalizers
    }
  },
}
</script>

<style lang="scss" scoped>
.totalizer-card {
  min-width: 250px;
  max-width: 500px !important;
  width: max-content !important;
}
.totalizer-help {
  position: absolute;
  top: 10px;
  right: 10px;
}
.circle {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 100%;
}
.circle.appointment{
  background-color: #1F4734;
}
.img_icon {
  width: 30px;
  height: 30px;
}
</style>

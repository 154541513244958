export const EA  = 1
export const EF = 2
export const EC = 3
export const ER = 4
export const ED = 5
export const MXA = 6
export const MXF = 7
export const MXC = 8
export const MXR = 9
export const MXD = 10
export const PA = 11
export const PF = 12
export const PC = 13
export const PR = 14
export const PD = 15
export const NA = 16
export const NF = 17
export const NC = 18
export const NR = 19
export const ND = 20
export const EA_PN  = 21
export const EF_PN = 22
export const EC_PN = 23
export const ER_PN = 24
export const ED_PN = 25
<template>
  <div>
    <b-sidebar
      id="sidebar-report-details-appointment"
      sidebar-class="sidebar-lg"
      :visible="appointmentReportDetailsSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateAppointmentReportDetailsSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4>{{ hasReport? "Detalhes do report": "Detalhes do agendamento" }}</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <template v-if="loading">
          <b-row class="justify-content-md-center mt-2">
            <b-spinner
              big 
              variant="primary"
            />
          </b-row>
        </template>
        <div class="m-2" v-if="appointment && !loading">
          <b-row>
            <b-col cols="6" class="mb-1">
              Nome do cliente: 
            </b-col>
            <b-col cols="6" class="mb-1">
              {{ appointment.customer_name }} 
            </b-col>
            <b-col cols="6" class="mb-1">
              Data de criação do evento: 
            </b-col>
            <b-col cols="6" class="mb-1">
              {{ appointment.created_at | dateTime }} 
            </b-col>
            <b-col cols="6" class="mb-1">
              Data inicial do agendamento: 
            </b-col>
            <b-col cols="6" class="mb-1">
              {{ appointment.appointment_start | dateTime }} 
            </b-col>
            <b-col cols="6" class="mb-1">
              Data final do agendamento: 
            </b-col>
            <b-col cols="6" class="mb-1">
              {{ appointment.appointment_end | dateTime }} 
            </b-col>
            <b-col cols="6" class="mb-1">
              PN responsável: 
            </b-col>
            <b-col cols="6" class="mb-1">
              {{ appointment.consultant.name }} 
            </b-col>
            <b-col cols="6" class="mb-1">
              PN especialista: 
            </b-col>
            <b-col cols="6" class="mb-1">
              <span v-if="appointment.consultant_specialist"> {{ appointment.consultant_specialist.name }}</span>
              <span v-else>Especialista não foi selecionado</span>
            </b-col>
            <b-col cols="6" class="mb-1">
              Tipo de compromisso: 
            </b-col>
            <b-col cols="6" class="mb-1">
              {{ appointment.appointment_envent_type.description }} 
            </b-col>
            <b-col cols="6" class="mb-1">
              Finalizou a pré entrevista: 
            </b-col>
            <b-col cols="6" class="mb-1">
              {{ appointment.finished_pre_interview? 'Sim' : 'Não' }} 
            </b-col>
            <template v-if="hasReport">
              <b-col cols="6" class="mb-1">
                Status: 
              </b-col>
              <b-col cols="6" class="mb-1">
                {{ appointment.appointment_report.status.description }} 
              </b-col>
              <b-col cols="6" class="mb-1">
                Data do Report: 
              </b-col>
              <b-col cols="6" class="mb-1">
                {{ appointment.appointment_report.createdAt | dateTime }} 
              </b-col>
              <b-col cols="6" class="mb-1">
                Houve reunião: 
              </b-col>
              <b-col cols="6" class="mb-1">
                {{ appointment.appointment_report.had_meeting | assertion }} 
              </b-col>
              <template v-if="appointment.appointment_report.closed_deal !== null
                && appointment.appointment_report.closed_deal !== ''"
              >
                <b-col cols="6" class="mb-1">
                  Fechou negócio: 
                </b-col>
                <b-col cols="6" class="mb-1">
                  {{ appointment.appointment_report.closed_deal | assertion }} 
                </b-col>
              </template>
              <template v-if="appointment.appointment_report.has_approval !== null
                && appointment.appointment_report.has_approval !== ''"
              >
                <b-col cols="6" class="mb-1">
                  Foi aprovado: 
                </b-col>
                <b-col cols="6" class="mb-1">
                  {{ appointment.appointment_report.has_approval | assertion }} 
                </b-col>
              </template>
              <template v-if="appointment.appointment_report.why 
                && appointment.appointment_report.why !== ''"
              >
                <b-col cols="6" class="mb-1">
                  Por quê: 
                </b-col>
                <b-col cols="6" class="mb-1">
                  {{ appointment.appointment_report.why }} 
                </b-col>
              </template>
            </template>
          </b-row>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BButton, BFormInput, BFormTextarea, BCol, BRow, BSpinner
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import * as types from '../store/types'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCol,
    BRow, 
    BSpinner
  },
  directives: { 
    Ripple,
  },
  data() {
    return {
      loading: false,
      appointment: undefined,
      hasReport: false
    }
  },
  setup() {
    return { toast: useToast()};
  },
  computed: {
    ...mapGetters({
      appointmentReportDetailsSidebar: types.APPOINTMENT_REPORT_DETAILS_SIDEBAR
    })
  },
  methods: {
    ...mapMutations({
      mutateAppointmentReportDetailsSidebar: types.MUTATE_APPOINTMENT_REPORT_DETAILS_SIDEBAR
    }),
    ...mapActions({
      getAppointment: types.GET_APPOINTMENT,
    }),
    onShow(){
      this.loading = true
      this.getAppointment(this.appointmentReportDetailsSidebar.id)
        .then(response => {
          this.appointment = response.data
          if(response.data.appointment_report?.id){
            this.hasReport = true
          }
        })
        .catch(err => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar o report do compromisso. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.hide
        })
        .finally(() => {
          this.loading = false
        })
    },
    clear(){
      this.appointment = undefined
      this.hasReport = false
    }
  }
}
</script>

<style lang="scss">
.sidebar-bottom {
  position: absolute;
  bottom: 0;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}
</style>

export const createWppInterviewMessage = (
  user_name,
  user_token,
  token_public
) => {
  var link =
    process.env.VUE_APP_FRONT_END_URL +
    "/autenticacao_cliente/" +
    token_public +
    "?back_url=pre_entrevista/" +
    user_token;

  //%0a = quebra de linha no whatsapp;
  //\u200a = caractere invisível;
  //texto entre * = negrito no whatsapp;
  //texto entre _ = itálico no whatsapp;
  var message = "Olá, " + user_name + "!";
  message +=
    "%0a%0aEspero que você esteja bem! Estou passando para relembrar da nossa última conversa por telefone e enfatizar a importância dessa próxima etapa. 👇";
  message +=
    "%0a%0aNa nossa última conversa, discutimos seus objetivos financeiros e como podemos ajudar a torná-los realidade. Essa mensagem é para relembrar o quanto é crucial o preenchimento do link abaixo! É a partir dele que entenderemos melhor a sua situação financeira e poderemos ajudá-lo a tomar as melhores decisões para alcançar os seus objetivos. 🤝";
  message += "%0a%0a🔗 Link para preenchimento: " + encodeURI(link);
  message +=
    "%0a%0aE lembre-se, seus dados estão seguros conosco, protegidos pela tecnologia da AWS. 🔒";
  message += "%0a%0aQualquer dúvida, estou à disposição.";
  return message;
};

export const createWppAppointmentMessage = (
  user_name,
  user_token,
  token_public
) => {
  var link =
    process.env.VUE_APP_FRONT_END_URL +
    "/autenticacao_cliente/" +
    token_public +
    "?back_url=pre_entrevista/" +
    user_token;

  var message = "Olá " + user_name + "! ";
  message +=
    "Estou te enviando um link de acesso para que você preencha um conjunto de informações iniciais antes da sua entrevista.";
  message +=
    " Acesse " +
    link +
    ", informe os dados que considerar relevantes e, ao finalizar, eu serei informado do preenchimento.";
  return message;
};

export const createWppNpsMessage = (
  name,
  link, 
) => {
  var message = `📣 Ei, ${name}, queremos a sua opinião!%0A%0A` +
    `Na Futuro, estamos sempre buscando melhorar e sua opinião é mega importante nessa jornada!%0A%0A` +
    `Então, conta pra gente: como tá sendo sua experiência com a Futuro até agora? Queremos saber tudo! 👀%0A%0A` +
    `Preparamos uma pesquisa super rápida (menos de 1 minuto!) só pra você. Sua opinião faz toda a diferença!%0A%0A` +
    `👉 É só clicar aqui: ${link}`;

  return message;
};


export const createWppCustomerMessage = (rec_name, rec_appointment) => {
  var message = `${rec_name} - ${rec_appointment}%0a`;
  return message;
};

export const notHadWhyOptions = [
  { name: "Cancelou" },
  { name: "Não quer mais" },
];

export const notClosedWhyOptionsForCustomer = [
  { name: "Acompanhamento" },
  { name: "Não possui condições financeiras" },
  { name: "Não quer" },
  { name: "Postergou a descisão" },
];

export const notClosedWhyOptionsForPN = [
    { name: "Candidato desistiu" },
    { name: "Candidato sem o perfil adequado" }
];

export const scores = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
];
